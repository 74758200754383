<template>
        <div id="highlight_words">    
            <span v-for="(item,index) in this.highlightWords"
                @click="selItem(item)"
                :class="{ clicked: opened.includes(item.id) }" :key="item.id">
                <span hidden>
                  <div>
                    <input type="checkbox"
                          :id="'item'+index"
                          :value="item.name"
                          v-model="checkedItems">
                  </div>
                </span>
                <span class="content_check">{{ item.name }}</span>
              </span>
        </div>
</template>

<script>
export default {
    name: 'highlightWords',
    components:{
    
    },
    props: {
        case_item: {
        default: () => true
        },
    },
    data() {
      return {
        highlightWords: [],
        notes: null,
        select: false,
        opened:[],
        checkedItems:[],
        store: [],
        form: {
            answer_options: []
        }
      }
    },
    mounted() {
       this.highlightWord()
    },
    methods:{
        highlightWord(){
             let txt = this.case_item.title.replace((/<span class="hidden">(.*?)<*span>/g), '')
             console.log('txt',txt)
             let text = txt.replace(/<[^>]+>/g, '')
             const Arrays = text.split(" ");
             const myArray = Arrays.map((item,index) => {
                    return {
                    name: item,
                    id: index+1
                    }
                }); 
             this.highlightWords = myArray
            console.log("Words",this.highlightWords)     
        },
        selItem(item){
            const index = this.opened.indexOf(item.id);
            if(index > -1){
                this.opened.splice(index,1);
                this.checkedItems.splice(index,1);
                const i = this.store.indexOf(item);
                if (i > -1) {
                this.store.splice(i, 1);
                }
            } else {
                this.opened.push(item.id);
                this.store.push(item)
                this.checkedItems.push(item);
            }
            console.log('store',this.store)
            const data = this.store.map(item => {
                return {
                  title: item.name.replace(/,/g, '').replace(/\./g,''),
                  serial_no: item.id,
                };
            });
            this.$root.answer_options = data
            console.log('data',data)
        },
    }
}
</script>
<style scoped>
#highlight_words{
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
}
.clicked {
  background-color: #f5b325;
  margin: 2px 2.5px;
  padding: 2px;

}
.content_check{
  margin: 0px 2px;
  cursor: pointer;
  display: inline-block;
}

</style>