<template>
    <div class="Pte_reading" >
        <div v-if="case_item.prompt != null">
            <h4 class="md-sl__porpule md-sl__font_600 pb-2 text-uppercase">prompt</h4>
            <md-content v-html="case_item.prompt" class="md-sl__font_600 mb-4 md-sl__content_color md-content__p1"></md-content>
        </div>
        
        <DropDown :case_item="case_item" v-if="this.short_title == 'FGDD'"/>
        <DrapAnddrop :case_item="case_item" v-if="this.short_title == 'FGDND'"/>
        <div class="md-question__radio md-sl__font_600" v-if="this.short_title == 'MCQMA'">
            <md-content v-html="case_item.title" class="md-sl__content_color p-2 round md-sl__font_600 md-sl__x1 mb-4 m-0" style="background:#fff0;color: #6616d0;">
            </md-content>
            <MultipleAnswer :case_item="case_item"/>
        </div>
        <div class="md-question__radio md-sl__font_600"  v-if="this.short_title == 'MCQSA'">
            <md-content v-html="case_item.title" class="md-sl__content_color p-2 round md-sl__font_600 md-sl__x1 mb-4 m-0" style="background:#fff0;color: #6616d0;">
            </md-content>
            <SingleAnswer  :case_item="case_item"/>
        </div>
        <ReOrder :case_item="case_item" v-if="this.short_title == 'ROP'" class="md-sl__x1  md-content__p1"/>
    </div>
</template>
<script>
import DropDown from "./QuestionTypeElements/dropDown.vue";
import DrapAnddrop from "./QuestionTypeElements/drapAnddrop.vue";
import MultipleAnswer from "./QuestionTypeElements/multipleAnswer.vue";
import SingleAnswer from "./QuestionTypeElements/singleAnswer.vue";
import ReOrder from "./QuestionTypeElements/reOrderParagrap.vue";
export default {
    name: 'Pte_reading',
    components:{
        DropDown,
        DrapAnddrop,
        MultipleAnswer,
        SingleAnswer,
        ReOrder
    },
    data() {
      return {
        short_title: '',
        form: {
            answer_options: [],
            answer:'This is an answer for a test which is taken for a Read Aloud'
        }
      }
    },
    props: {
        case_item: {
        default: () => true
        },
    },
    mounted() {
       this.map()
    },
    methods:{
         map(){
            this.short_title = this.case_item.question_type.short_title
         }
    }
}
</script>
