<template>
    <div>
        <div>
            <!-- <div v-if="case_item.title != null">
                <md-content 
                   v-html="case_item.title" 
                   class="md-sl__font_600 md-sl__x1 mb-4 md-content__p1">
                </md-content>
            </div> -->
            <div v-if="case_item.prompt != null" class="md-question__radio md-sl__font_600 pb-4">
               <md-content 
                    v-html="case_item.prompt" 
                    class="md-sl__font_600 md-sl__x1 mb-4 md-sl__content_color md-content__p1">
                </md-content>
            </div>
            <md-content >
                <Audio class="litening_body" :src="path" :case_item="case_item"/>
            </md-content>
        </div>
        <div class="br-4" >
            <div class="pl-2 pt-2 md-sl__content_color md-sl__x1  md-question__radio md-sl__font_600" v-if="this.short_title == 'HIW'">
                    <h3 class="md-sl__porpule text-uppercase">answer</h3>
            </div>
            <div class="md-question__radio md-sl__font_600">
                <div class="Pte_reading md-sl__x1">
                    <MultipleAnswer 
                       :case_item="case_item" 
                       v-if="this.short_title == 'MCQMA'"
                    />
                    <SingleAnswer 
                       :case_item="case_item" 
                       v-if="this.short_title == 'HCS' || this.short_title == 'SMW' || this.short_title == 'MCQSA'"
                    />
                </div>
                <SummarizeSpokenTest 
                   @input="input" v-if="this.short_title == 'SST'"
                />
                <WriteFromDictation @input="input" v-if="this.short_title == 'WFD'"/>
            </div>
            <md-content 
                class="md-sl__x1 md-sl__font_600 p-1 pb-4 md-sl__content_color" 
                v-if="this.short_title == 'HIW'">
                <HighlightInCorrectWord :case_item="case_item"/>
            </md-content>
            <md-content 
                class="md-sl__x1 md-sl__font_600 p-1 pb-4 md-sl__content_color" 
                v-if="this.short_title == 'FG'">
                <FillInTheGap :case_item="case_item"/>
            </md-content>
            <md-content class="md-sl__font_600 md-sl__x1 mt-4" v-if="transciptEvent">
                <div class="pl-4 md-question__radio md-sl__font_600 md-sl__content_color" v-if="this.attemt > 0">
                    <h3 class="pt-2 md-sl__porpule text-uppercase">Transcript</h3>
                    <span v-html="case_item.transcript" class="pt-1 pb-2"></span>
                </div>
            </md-content>
        </div>
        
    </div>
</template>
<script>
import Audio from '../audio.vue';
import MultipleAnswer from "./QuestionTypeElements/multipleAnswer.vue";
import SingleAnswer from "./QuestionTypeElements/singleAnswer.vue";
import SummarizeSpokenTest from "./QuestionTypeElements/SummarizeSpokenTest.vue";
import HighlightInCorrectWord from "./QuestionTypeElements/highlightinCorrectWord.vue";
import FillInTheGap from "./QuestionTypeElements/fillInTheGap.vue";
import WriteFromDictation from "./QuestionTypeElements/writeFromDictation.vue";
export default {
    name: 'Listening',
    components:{
      Audio,
      MultipleAnswer,
      SingleAnswer,
      SummarizeSpokenTest,
      HighlightInCorrectWord,
      FillInTheGap,
      WriteFromDictation
    },
    props: {
        case_item: {
        default: () => true
        },
        transciptEvent:{
            default: () => true
        },
        path: {
             default: () => true
        },
        activevalid:{
             default: () => true
        },
    },
    data() {
      return {
        short_title: '',
        title: '',
        prompt: '',
        attemt: 0,
        form: {
            answer_options: []
        }
      }
    },
    mounted() {
       this.map();
    },
    methods:{
        map(){
            this.short_title = this.case_item.question_type.short_title
        },
        input(value){
            this.$root.answer = value
            console.log('ddd',this.$root.answer)
        }
    }
}
</script>
