<template>
  <div class="copy">
    <div class="item" id="heightDetect">
      <h3 class="text-uppercase text-victoria pb-3">SOURCE</h3>
        <Container
            class="md-drop-item pb-3"
            group-name="1"
            id="height"
            style="height:calc(100% - 32px);"
            :get-child-payload="getChildPayload1"
            @drop="onDrop('questions',$event)" 
        >
        <Draggable v-for="(item,index) in this.questions" :key="index">
          <div class="draggable-item md-drop-item mt-3 ml-3 mr-3 p-2 flex align-baseline text-justify font-bold">
            <span>{{item.value}}</span>
          </div>
        </Draggable>
      </Container>
    </div>
    <div class="flex align-center pt-10">
      <div>
        <div>
       <ArrowIcon class="arrowIcon back-blue text-white"/>
       </div>
       <div>
       <ArrowIcon class="arrowIcon arrorRight back-gray text-white"/>
       </div>
     </div>
    </div>
    <div class="item">
      <h3 class="text-uppercase text-victoria pb-3">TARGET</h3>
      <Container
        class="md-drop-item pb-3"
        group-name="1"
        style="height:calc(100% - 32px);"
        :get-child-payload="getChildPayload2"
        @drop="onDrop('correct',$event)" 
      >
        <Draggable v-for="(item,index) in correct" :key="index">
          <div class="draggable-item md-drop-item mt-3 ml-3 mr-3 p-2 flex text-justify font-bold">
            <span> {{ item.value}}</span>
            <!-- <div class="flex align-center pt-1" style="right: 20px;position: absolute;" @click="removeItem(index)">
              <md-icon class="text-m md-close z-6 cursor mr-4">close</md-icon>
            </div> -->
          </div>
        </Draggable>
      </Container>
    </div>
    <div class="flex align-center pt-10">
      <div style="position:absolute">
        <div>
          <ArrowIcon class="arrowIcon arrorDown back-blue text-white"/>
       </div>
       <div>
          <ArrowIcon class="arrowIcon arrorUp arrorRight back-blue text-white"/>
       </div>
     </div>
    </div>
  </div>
</template>
<script>
import { Container, Draggable } from "vue-dndrop";
import { applyDrag } from "../../../../../utils/helpers";
import ArrowIcon from '../../../../../assets/svg/arrowRignt.svg';
export default {
  name: "Copy",
  components: { Container, Draggable,ArrowIcon },
  props: {
    case_item: {
      default: () => true
    },
  },
  data() {
    return {
      correct:[],
      questions: '',
      form: {
        answer_options: []
      }
    };
  },
  mounted(){
     setTimeout(() => this.Sort(),10)
        
    },
    methods:{
    Sort(){
      this.questions =  this.case_item.reorder_incorrect_pairs.sort(() => Math.random() - 0.5)
      this.questions.forEach((item,i) => {
        item.new_serial = i+1;
      });
      console.log(this.questions);
      if(this.questions != null){
          this.$root.incorrect_order = this.questions
      }
      //this.questions = this.case_item.reorder_incorrect_pairs.sort((a, b) => a.serial_no - b.serial_no)
    },
    onDrop(collection, dropResult) {
      this[collection] = applyDrag(this[collection], dropResult);
      this.correct.forEach((item,i) => {
        item.title = item.value;
        item.serial_no = i+1;
        item.key = item.keys
      });
      console.log('1',this.correct)
      const array = this.correct.filter((v,i,a)=>a.findIndex(t=>(t.key === v.key))===i);
      this.correct = array;
      this.$root.answer_options = this.correct
      //console.log('correct_pairs:',this.case_item.reorder_incorrect_pairs, this.correct)
    },
    getChildPayload1(index) {
      console.log(this.questions);
      return this.questions[index];
    },
    getChildPayload2(index) {
      return this.correct[index];
    },
  },
};
</script>

<style>
.copy {
  display: flex;
  gap: .8rem;
}
.item {
  flex: 1;
}
.arrowIcon{
  padding: 4px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  zoom: .9;
}
.arrorRight{
  transform: rotate(178deg);
}
.arrorUp{
      transform: rotate(270deg);
}
.arrorDown{
      transform: rotate(90deg);
}
.z-6{
  zoom: .76;
}
.md-drop-item{
  padding: 0;
}
#textarea {
    overflow: hidden;
    margin: 5px 0px;
    word-wrap:break-word;
    resize: none;
    width: 100%;
    border-color: #ff000000;
}
.md-drop-item .md-close {
    right: -36px;
    top: -20px;
}
#height{
  min-height: 380px !important;
}
</style>