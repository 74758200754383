<template>
  <div class="md-pte__dialog_body">
    <md-dialog-confirm
      :md-active.sync="active"
      class="md-pte__dialog_body"
      md-title="Are you sure close this page?"
      md-content="If you <strong>leave</strong> before PTE Test, your changes will be <strong>lost.</strong>"
      md-confirm-text="Agree"
      md-cancel-text="Disagree"
      @md-cancel="onCancel"
      @md-confirm="onConfirm"/>
    <md-button class="md-back__raised md-raised" @click="active = true">
      <div>
        <ArrowIcon/>
      </div>
      Back
    </md-button>
  </div>
</template>

<script>
import ArrowIcon from '@/assets/svg/arrowIcon.svg';
  export default {
    name: 'DialogConfirm',
    components:{
      ArrowIcon,
    },
    props: {
      path: {
        type: String,
        default: 'student.ptePractice.nav'
      }
    },
    data: () => ({
      active: false,
      value: null,
      layoutMeta:'student'
    }),
     computed: {
    type() {
      return this.$route.query.type
    },
  },
  methods: {
    onConfirm () {
      this.value = 'Agreed';
      this.$emit("dailogHide", false);
      if(this.layoutMeta=='student'){
      this.$router.push({
        name: this.path,
      });
      }else{
        this.$router.push({
          name: this.$route.meta.layout === 'master' ? 'master-testCreation.list': 'testCreation.list',
         });
      }
    },
    onCancel () {
      this.value = 'Disagreed'
    }
  },
   mounted(){
      
      this.layoutMeta = this.$route.meta.layout;  
     
    },
}
</script>
