<template>
  <div class="md-prograss__s">
    <div class="md-progress-bars">
      <div class="progress_1">
        <progress class="progress-bars" :value="value" :max="preparation_actual_time" id="progressBar"></progress>
      </div>
    </div>
    </div>
</template>
<script>

export default {
  name: 'md-prograss__s',
  data () {
    return {
      width: '',
      barprograss: '',
      count : '',
      //mutabledata: 5
      preparation_time: 0,
      preparation_actual_time: 0,
      value: 0,
    }
  },
  methods: {
    countDownTimer() {
      if(this.preparation_time > 0) {
        setTimeout(() => {
          this.preparation_time -= 1;
          this.value = this.preparation_actual_time - this.preparation_time;
          // document.getElementById("progressBar").value = 5 - this.preparation_time;
          this.countDownTimer();
        }, 1000)
      }
    },
  },
}
</script>
<style scoped>
.md-progress-bars{
    padding: 15px;
    border: 1px solid #bebebe;
    margin: 25px 0px;
    border-radius: 4px;
}
.progress-bars {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #7432b8;
    transition: width .6s ease;
    min-height: .6rem;
    width: 100%;
}
progress[value]::-webkit-progress-value {
  background-image:-webkit-linear-gradient(left, #7432b8, #7432b8);
  border-radius: 2px; 
  background-size: 35px 20px, 100% 100%, 100% 100%;
    
}
.progress_1 {
    display: -ms-flexbox;
    display: flex;
    height: .6rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
</style>