<template>
<div class="av-media-demo pte_stu">
  <div class="md-sl__playerBody">
    <div class="md-sl__wave_body">
      <div class="md-sl__wave_text">
        <b v-if="this.x > 0">AUDIO STARTING AT <span class="md-sl__porpule">{{this.x}} SEC</span></b>
        <b v-if="this.x < 1">
          <span v-if="!this.hidePlayOtion">
            <span v-if="this.CurrentTime != '00:00'">AUDIO STARTING</span>
          </span>
        </b>
        <b v-if="this.hidePlayOtion">
          <span v-if="this.isActive" class="md-sl__porpule">AUDIO IS PLAYING</span>
          <span v-if="!this.isActive" class="md-sl__porpule">AUDIO IS PUASE</span>
        </b>
       <b v-if="this.CurrentTime == '00:00'"><span class="md-sl__porpule">AUDIO IS COMPLETED</span></b>
      </div>
      <div class="md-sl__wave_text" style="right:10px;display: flex;" v-if="this.case_item.question_type.short_title != 'SMW'">
        <p id="currentTime">{{currentTime}}</p>/ 
        <p id="totalDuration">{{this.totalDuration}}</p>
        
      </div>
      <div class="md-sl__wave_text md-volume_control_button">
          <span class="md-muteHandle_icon" @click="Mutehandle()" v-bind:class="{ mute: isSoundActive}">
            <MuteIcon/>
          </span>
          <input @click="unMutehandle()" data-action="volume" id="VolumeControl" type="range" min="0" max="1" value=".5" step="0.1" style="width: 100%" >
      </div>
      <!-- {{src}} -->
      <vue-wave-surfer ref-link="player" :src="src" :options="options" ref="surf" id="waveform" class="md-sl__wave"></vue-wave-surfer>
      <div v-if="this.x < 1" @click="play()" v-bind:class="{ active: isActive}" class="play_button__mode">
         <span><SoundIcon/></span>
      </div>
    </div>
   </div>

</div>
</template>
<script>
import SoundIcon from '../../../assets/svg/sound.svg';
import MuteIcon from '../../../assets/svg/muteIcon.svg';
export default {
  name: 'av-media-demo',
  components:{
    SoundIcon,
    MuteIcon,
  },
  props: {
    src: {
      default: true
    },
    case_item:{
      default: true
    },
    is_beep:{
      default: true
    }
  },
  data () {
    return {
      file_path: '',
      options: {
        barGap: 3,
        barWidth: 1.3,
        barHeight:.7,
        mediaType:"audio",
        normalize: false,
        partialRender: false,
        progressColor: "#8c58c2",
        waveColor:"#ced2e0",
        interact:false,
        cursorWidth: 2,
        responsive: true,
        backend:"MediaElement",
        audioScriptProcessor:"none"
      },
      x: 5,
      isActive: '',
      isSoundActive: '',
      counterTotal:'',
      CurrentTime: '',
      hidePlayOtion: false,
      value: 0.9,
      soundcontrol: '',
      stop: '',
      totalDuration: '00:00',
      currentTime: '00:00'
    }
  },
  watch:{
    src: {
      handler(val){
        this.file_path = val;
        console.log(val);
        if(val != null){
          this.audioprocess.load(val);
        }
      },
    }  
  },
   mounted () {
    this.audioprocess.on('loading', function(percents) {
      document.getElementById('waveform').value = percents;
      console.log('percents==========',percents);
    });
    this.audioprocess.on('ready', () => {
      // Set audio track total duration
      const formatTimecode = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5)
      }
      const duration = this.audioprocess.getDuration()
      //console.log(formatTimecode(duration));
      this.totalDuration = formatTimecode(duration);

     // this.play()
      if(this.audioprocess.isPlaying()){
          this.isActive = true
      }
      if(!this.audioprocess.isPlaying()){
          this.isActive = false;
      }
    });

    // Audio Timer count
    this.audioprocess.on("audioprocess", () => {
      const formatTimecode = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5)
      }
      const time = this.audioprocess.getCurrentTime();
      this.CurrentTime = formatTimecode(this.audioprocess.getDuration() - time);
      this.currentTime = formatTimecode(time);

      if (this.CurrentTime == '00:00') {
        this.stop = 'prograss';
        this.isActive = false;
        this.CheckPlay = true;
        this.hidePlayOtion = false;
        if(this.case_item.question_type.short_title == 'SMW'){
          var audio = new Audio('https://kazi-blubird.sfo2.digitaloceanspaces.com/apical/speaking/audio/beep-03.mp3');
          setTimeout(() => {
            audio.play();
            audio.loop = false;
          }, 1000);
          
        }  
      }
     
      // volume 
      const volumeslider = document.querySelector('#VolumeControl');
        volumeslider.addEventListener("mouseup",() => {
          changevolume(volumeslider.value)
        });
        const  changevolume = (volume) =>{
        this.audioprocess.setVolume(volume)
        console.log(volume) 
       }  
       
    })
    
    
  },
  
  methods: {
    play(){
      this.audioprocess.playPause();
      this.hidePlayOtion = true;
      if(this.audioprocess.isPlaying()){
        this.isActive = true;
        
      }
      if(!this.audioprocess.isPlaying()){
          this.isActive = false;
          clearInterval(this.CurrentTime);
      }
    },
    Mutehandle(){
      this.audioprocess.setMute(true)
      this.isSoundActive = true;                   
    },
    unMutehandle(){
      this.audioprocess.setMute(false)
      this.isSoundActive = false;                  
    },
    countDownTimer() {
      if(this.x == 0){
        //this.audioprocess.play();
        //this.isActive = true
      }
      if(this.x > 0) {
          setTimeout(() => {
              this.x -= 1;
              this.countDownTimer();  
          }, 1000)
      }
      if(this.x == 0){
        //this.hidePlayOtion = true
      }  
    },
  },
  computed: {
    audioprocess() {
      return this.$refs.surf.waveSurfer;
    },
    handler() {
      return this.src;
    }
  },
  created(){
    this.countDownTimer(); 
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.md-sl__wave{
    border: 1px solid #bebebe;
    border-radius: 4px;
    background: #fff;
}

.md-sl__wave_body{
    background: #bcbcbc45;
    margin-left: 20px;
    padding: 10px;
    margin-bottom: 70px;
    border-radius: 4px;
}
.play_button__mode.active .puase{
 fill: none;
}
.play_button__mode .play{
  fill: none;
}
.play_button__mode.active .play{
  fill: #fff;
}
.play_button__mode  {
  position: absolute;
  top: 40%;
  z-index: 2;
}
.play_button__mode  span{
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #ffffffb8;
    box-shadow: 0 2px 5px 1px rgb(158 158 158 / 50%);
    position: absolute;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}
.play_button__mode.active  span{
    background: #7432b8;
}
.play_button__mode svg{
    position: absolute;
    margin-left: 5px;
    width: 45px;
    height: 45px;
}
.md-volume_control_button{
    right: 20px;
    top: 42.5%;
    width: 90px;
    border: 1px solid;
    display: flex;
    border-radius: 3px;
    height: 30px;
    justify-content: flex-end;
    align-items: center;
}
.md-volume_control_button input[type='range']{
  zoom: .65;
  width: 75px !important;
  background-image:-webkit-linear-gradient(left, #7432b8, #7432b8);
  cursor: pointer;
}
#waveform{
  zoom: .85;
  padding-top: 10px;
}
/* input[type='range']::after{
  content: '';
  width: 11px;
  height: 11px;
  background: #7432b8;
  position: absolute;
  right: 6px;
  margin-top: -4px;
  border-radius: 50px;
} */
input[type='range'] {
  width: 40px !important;
  -webkit-appearance: none;
   background: #434343;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  -webkit-appearance: none;
  margin-top: -1px;
  background-color: #7432b8;
}

input[type='range']::-webkit-slider-thumb {
  width: 5px;
  -webkit-appearance: none;
  height: 5px;
  cursor: ew-resize;
  background: #434343;
  box-shadow: -4px 0 0 4px #7432b8;
  z-index: 111;
  border-radius: 50px;
}
input[type="range"]::-moz-range-track {  
  background-color: #434343;
}
input[type="range"]::-ms-fill-upper {  
  background-color: #434343;
}
.md-muteHandle_icon{
  margin-right: -6px;
  z-index: 11;
}
.md-muteHandle_icon .circle_lg{
  stroke-dasharray: 600px 1500px;
  transition: all .5s;
}

.md-muteHandle_icon .circle_sm{
  opacity: 1;
  transition: all .25s;
}

.mute svg .circle_lg{
  stroke-dashoffset: -1360px;
}

.mute svg .circle_sm{
  opacity: 0;
}
</style>