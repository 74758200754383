<template>
    <div>
        <SummarizeWritten :case_item="case_item" v-if="this.case_item.question_type.short_title === 'SWT'"/>
        <WriteEssay :case_item="case_item" v-if="this.case_item.question_type.short_title === 'ES'"/>
    </div>
</template>
<script>
import SummarizeWritten from "./QuestionTypeElements/SummarizeWritten.vue";
import WriteEssay from "./QuestionTypeElements/WriteEssay.vue";
export default {
    name: 'Writing',
    components:{
      SummarizeWritten,
      WriteEssay
    },
    props: {
        case_item: {
          default: () => true
        }
    },
    data() {
      return {
        
      }
    },
    computed: { 
    },
    methods:{
    },
}
</script>
