<template>
    <div>
        <div class="ml-2 mr-1" v-html="this.highlightWords" id="fill_in_gap" @change="highlightSelect(highlightWords)"> 
        </div>
    </div>   
        
</template>

<script>
export default {
    name: 'highlightWords',
    components:{
    
    },
    props: {
        case_item: {
        default: () => true
        },
    },
    data() {
      return {
        highlightWords: [],
        notes: null,
        select: false,
        value:'',
        store: [],
        data:[],
        results:[
           {
               value: 'nb',
               title: 'nb',
               key: '1',
               serial_no: '1',
               is_correct_answer: true
           }
        ],
        form: {
            answer_options: []
        }
      }
    },
    mounted() {
       this.highlightWord()

    },
    methods:{
        highlightWord(){
            let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
             console.log('4',text)
            this.highlightWords = text.replace(/ (\/)?\[[^\]]*]/g, "<span><input @change='changeValue'/></span>")
            console.log('g',this.highlightWords)
        },
        highlightSelect(){
            const tx = document.querySelectorAll('#fill_in_gap > span input')
                for (let i = 0; i < tx.length; i++) {
                    tx[i].classList.add('fill_in_gap'+`${i+1}`)
                    tx[i].setAttribute("type","text");
                    tx[i].setAttribute("v-model","value");
                    if(document.getElementsByClassName("fill_in_gap"+`${i+1}`)){
                        this.value = document.getElementsByClassName("fill_in_gap"+`${i+1}`)[0].value
                        this.store = {title: this.value,key: i+1,serial_no: i+1}
                        this.data.push(this.store); 
                    }
               } 
               const results = this.data.filter(element => {
                return element.title !== '';
                });
                const answer = results.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.key === value.key
                    ))
                )
                this.$root.answer_options = answer;
                console.log('hello',this.$root.answer_options)
                

        },
    }
}
</script>
<style scoped>

</style>
