<template>
    <div class="md-sl__x1">
        <div class="p-4 md-sl__q_color br-4">
            <span class="flex justify-end back-blue text-white text-uppercase p-1 pr-2">
                <p class="weight-600">word count: {{this.$root.wordCounter}}</p>
            </span>
            <span class="text_clip_body">
                <textarea
                    @input="$emit('input', $event.target.value)" 
                    contenteditable='true' 
                    ref="text" 
                    v-model="value" class="p-2">
                </textarea>
            </span>
            <span class="flex pt-2" style="justify-content: space-around;">
                <span class="button_text_field pl-2 pr-2 p-1 br-4 text-uppercase cursor" @click="cutToClipboard">Cut</span>
                <span class="button_text_field pl-2 pr-2 p-1 br-4 text-uppercase cursor" @click="copyToClipboard">Copy</span>
                <span class="button_text_field pl-2 pr-2 p-1 br-4 text-uppercase cursor" @click="pasteToClipboard">paste</span>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'textEditor',
    components:{
       
    }, 
    data() {
        return {
            wordCounter: '',
            lineCount: '',
            value: '',
            evaluation_details: []
        }
    },
    methods: {
        copyToClipboard() {
           this.$refs.text.select();
           document.execCommand('copy');
        },
        cutToClipboard() {
           this.$refs.text.select();
           document.execCommand('cut'); 
           this.$refs.text.blur();
        },
        pasteToClipboard() {
            this.$refs.text.value = '';
            navigator.clipboard.readText()
            .then((text)=>{
                this.$refs.text.value = text
            })
        },
    },
};
</script>
