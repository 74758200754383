<template>
     <div>
        <div class="pl-4 md-question__radio md-sl__font_600 md-sl__content_color">
            <li v-for="(item, index) in this.list" :key="index" class="flex align-center">
                <md-checkbox v-model="array" :value="index"> </md-checkbox>
                <span>{{item.title}}</span>
            </li>
        </div>
    </div>
</template>
<script>
export default {
    name: 'multiple',
    data() {
      return {
          radio: 'accent', 
          array: [],
          list:'',
          form: {
              answer_options: []
           }
      }
    },
    props: {
        case_item: {
          default: () => true
        },
    },
    mounted(){
        this.list = this.case_item.question_options.sort(() => Math.random() - 0.5);
        this.list.forEach((item,i) => {
              item.new_serial = i+1;
        });
    },
    watch: {
    array(value) {
        this.case_item.question_options.map(item => delete item.drop_down_options);
        //this.case_item.question_options[value].is_correct_answer = true;
        this.case_item.question_options.forEach((item) => {
            item.is_correct_answer = false ;
        });
        for( var i = 0; i < value.length; i++){
            let x = value[i]
            this.case_item.question_options[x].is_correct_answer = true;
        }
        this.$root.answer_options = this.case_item.question_options
         // this.$emit('input', this.case_item.question_options);
        console.log(this.case_item.question_options)
    }
  },
   
}
</script>