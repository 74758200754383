<template>
     <div>
        <div class="md-sl__content_color" style="padding: 0.1px;">
            <ul>
                <li v-for="(item, index) in this.list" :key="index" class="my-3 flex align-center">
                    <md-radio v-model="radio" :value="index" class="md-primary ml-2"> </md-radio>
                    <span>{{item.title}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'single',
    data() {
      return {
        radio: '1', 
        list:'',
        form: {
            answer_options: []
        }
      }
    },
    props: {
        case_item: {
        default: () => true
        },
    },
    mounted(){
        this.list = this.case_item.question_options.sort(() => Math.random() - 0.5);
        this.list.forEach((item,i) => {
              item.new_serial = i+1;
        });
    },
    watch: {
        radio(value) {
            this.case_item.question_options.map(item => delete item.drop_down_options);
            this.case_item.question_options.forEach((item) => {
              item.is_correct_answer = false ;
            });
            this.case_item.question_options[value].is_correct_answer = true;
            this.$root.answer_options = this.case_item.question_options
            console.log(this.case_item.question_options)
        }
    }
   
}
</script>