<template>   
    <TextField 
        @input="input"
    />
</template>
<script>
import TextField from "./textFieldBox.vue";
export default {
    name: 'SST',
    components:{
      TextField
    },
    data() {
      return {
          no_punctuation: true,
          capital_letters: false,
          bullet_points: true
      }
    },
    methods:{
        input(value){
            this.$emit("input", value);
            // detect punctuation
            let punctuation = value.match(/[.!?,]/g);
            if(punctuation != null){
               this.$root.no_punctuation = false
            } else {
                this.$root.no_punctuation = true
            }
            console.log(punctuation,'punctuation',this.$root.no_punctuation)
            
            // detect only_capital_letters
            let CapitalLetters = value.match(/[a-z]/g);
            if(CapitalLetters != null){
               this.$root.only_capital_letters = true
            } else {
                this.$root.only_capital_letters = false
            }
            console.log(CapitalLetters,'CapitalLetters',this.$root.only_capital_letters)

            // detect only_bullet_points
            // let BulletPoints = value.match(/[a-z]/g);
            // if(BulletPoints != null){
            //    this.bullet_points = true
            // } else {
            //     this.bullet_points = false
            // }
            // console.log(BulletPoints,'BulletPoints',this.bullet_points)
            
        },
    },
}
</script>
