<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content id="handleItem" class="p-3 br-3 md-sl__content_color md-sl__font_600 md-sl__x1 mb-4 m-0">
                <div  v-html="this.highlightWords" class="answers" id="fill_in_gap">
                </div>
                <div class="answer_body">
                    <div id="dropDown">  
                        <select @input="onChangeSelect" v-for="(item, index) in this.question_options" :key="index" >
                            <option disabled selected value></option>
                            <option :value="items.id" v-for="(items, index) in item.drop_down_options" :key="index">
                                {{items.dd_value}}
                            </option>
                        </select>
                    </div>
                </div>
            </md-content>
        </div>
    </div>
</template>

<script>
// import DrapFiled from "./drapFiled.vue";
export default {
    name: 'DropDown',
    data() {
      return {
          id: '',
          event: '',
          array:[],
          answer: [],
          highlightWords: [],
          question_options:[],
          form: {
            answer_options: []
          }
      }
    },
    props: {
        case_item: {
        default: () => true
        },
    },
    mounted(){
        this.highlightWord();
        setTimeout(() => this.data(),500);
        this.Sort()       
    },
    methods:{
        Sort(){
            this.question_options = this.case_item.question_options.sort((a, b) => a.serial_no - b.serial_no)
            this.case_item.question_options.map(item =>item.drop_down_options.sort(() => Math.random() - 0.5))

           console.log(this.drop_down_options)
        },
        onChangeSelect(val){
            let value = val.target.value;
            console.log(value)
            this.case_item.question_options.map((item,i) => 
            item.drop_down_options.map(item => item.serial_no = i+1))
            const answer_options =  this.case_item.question_options.map(item => 
            item.drop_down_options.find(item => item.id == value && item.id != undefined));
            const x = answer_options.filter(x => x !== undefined); 
            const y = x.map(({  
                question_option_id: question_options_id, 
                id: drop_down_option_id,
                serial_no:serial_no,
                serial_no:key,
                dd_value: title}) => ({ 
                    question_options_id,
                    drop_down_option_id,
                    title,
                    serial_no,
                    key
            }))
            for(var i = 0; i < y.length; i++){
                this.array[i] = y[i] ;
                this.array.key = i+1
                try {
                    const index = this.answer.findIndex(a => a.question_options_id === this.array[i].question_options_id);
                if(index > -1) {
                    this.answer.splice(index, 1);
                }
                } catch(e) {
                    console.log(e);
                }
                this.answer.push(this.array[i]);
                console.log(this.answer)
            }
            this.$root.answer_options = this.answer     
        },
        highlightWord(){
            let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
            //console.log(text)
            //this.highlightWords = text.replace(/<(\/)?i([^>]*)/g, '<$1span id="dropdown"$2><$1span id="remove"$2>');
            this.highlightWords = text.replace(/ (\/)?\[[^\]]*]/g, '<$1span id="dropdown"$2><$1span id="remove"$2></$1span></$1span>');
            //console.log('fillter',this.highlightWords)
        },
        data(){
            const tx = document.querySelectorAll('#dropdown')
            for (let i = 0; i < tx.length; i++) {
                tx[i].id = "fill_in_gap"+`${i+1}`;  
            } 
            const val = document.querySelectorAll('#dropDown > select')
            for (let j = 0; j < val.length; j++) {
                val[j].id =("fill_in_gaps"+`${j+1}`); 
                val[j].classList.add('fill_in_gap')
            } 
            const x = document.querySelectorAll('#fill_in_gap > span')
            for (let k = 0; k < x.length; k++) {
                const node = document.getElementById("fill_in_gaps"+`${k+1}`)
                document.getElementById("fill_in_gap"+`${k+1}`).appendChild(node); 
                document.getElementById("remove").remove()
            } 
        },
    }
}
</script>

<style scoped>
.answers .target{
    border: 1.5px solid #cfcaca;
    min-width: 110px;
    margin: 0 3px;
    text-align: center;
    min-height: 30px;
}
.answer_body{
    display: none;
}
.back-light_gray{
   background: #c6d0d9
}
.back-gray-light{
   background: #e6e4e5
}
.target::before{
    content: '1';
    position: absolute;
}
.target span{
    background: whitesmoke;
    z-index: 1;
}
</style>