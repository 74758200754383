<template>
    <div>
        <div class="md-question__radio md-sl__font_600">
            <md-content id="handleItem" class="p-3 br-3 md-sl__content_color md-sl__font_600 md-sl__x1 mb-4 m-0">
                <div v-html="this.highlightWords" class="answers align-center" id="fill_in_gap">
                </div>
            </md-content>
            <div style="flex-wrap: wrap" class="justify-center dropzone fill_Gap_answer br-3 pl-2 pr-2 md-sl__font_600 flex pb-3" >
                <div  :id="'drop_remove'+`${index+1}`" style="min-height: 30px;" v-for="(item, index) in this.question" :key="index" v-on:dragover="allowDrop" v-on:drop="drop" :class="'droptarget'+`${index+1}`" class="back-gray-light p-2 droptarget drop_body flex cursor align-center justify-center pt-1 pb-1 mt-3">
                    <span style="min-width: 90px;"   class="cursor-grab p_events p_drop text-center" 
                        v-on:dragenter="dragenter1"  v-on:dragstart="dragStart"  v-on:drag="dragging"  draggable="true"  :id="'droptarget'+`${index+1}`" >
                       {{item.title}}
                    </span>
                    <div id="FillInGap" class="fill_gap"> 
                        <i v-on:drop="drop" v-bind:class="[index > 0 ? 'active_red' : 'active_green']">
                            <span 
                                v-on:drop="dropFinder(index)"
                                v-on:dragstart="dragStart1"
                                v-on:dragover="allowDrop1"
                                v-on:dragenter="dragenter"
                                :class="'droptarget'+`${index+1}`"
                                :id="'dropzone'+`${index+1}`"
                                class="data cursor-grab droptarget align-center flex justify-center target md-sl__content_color br-3"
                            >&nbsp;   
                            <p class="remove_index" style="position: absolute">{{index+1}}</p>
                            </span>
                        </i>
                    </div>
                </div>
            </div>
          <p id="demo"></p>
        </div>
    </div>
</template>

<script>
// import DrapFiled from "./drapFiled.vue";
export default {
    name: 'DropDown',
    data() {
      return {
        id: '',
        event: '',
        dragged:'',
        gap_length: null,
        highlightWords: [],
        answer: [],
        answers: [],
        question:[],
        index: '',
        title:'',
        Questionindex: '',
        question_option: [],
        form: {
            answer_options: []
        }
      }
    },
    props: {
        case_item: {
        default: () => true
        },
    },
    mounted(){
        this.highlightWord();
        setTimeout(() => this.data(),500);
        this.question = this.case_item.question_options.sort(() => Math.random() - 0.5)
    },
    methods:{
        dropFinder(index){
            this.$root.gap_length = index+1; 
            console.log('index',index+1);
            
        },
        dragStart1(){
            const x = document.querySelectorAll('.p_events')
            for (let i = 0; i < x.length; i++) {
                x[i].classList.add("disable_p_events");  
            } 
         
        },
        dragenter(event){
            const sx = document.getElementById(this.$root.index)
             if(this.$root.index.length > 1){
                sx.classList.remove("disable_pt_events");
            } 
            console.log(event.target.children,sx)  
        },
        dragenter1(event){
            console.log('event1',event.target);
            
        },
        dragStart:function(event)  {
            event.dataTransfer.setData("Text", event.target.id); 
        },
        dragging:function() {
            document.getElementById("demo").innerHTML = "The element is being dragged";
        },
        allowDrop:function(event) {
            event.preventDefault();  
            console.log('event.target.id',this.$root.index)
            const sx = document.getElementById(event.target.id)
            sx.classList.remove("disable_pt_events"); 
        },
        allowDrop1:function(event) {
            event.preventDefault(); 
            console.log('sxfff',event.target.children.length > 0) 
            this.$root.index = event.target.id
            const sx = document.getElementById(event.target.id)
            if(event.target.children.length > 1){
                sx.classList.add("disable_pt_events");
            }  
             setTimeout(function(){
                if(event.target.children.length > 1){
                   sx.classList.remove("disable_pt_events");
                }
            }, 2500);
        },
        drop(event) {
            event.preventDefault();         
            console.log('ww',event.dataTransfer.getData("Text"))
            var data = event.dataTransfer.getData("Text");
            
            const dx = document.querySelectorAll('.p_events')
            for (let i = 0; i < dx.length; i++) {
                dx[i].classList.remove("disable_p_events");  
            } 
            if(data != undefined){
                let value = data.replace(/(droptarget)/g, "");
                const title  = document.getElementById('droptarget'+`${value}`).textContent;
                //console.log(title)
                this.$root.Questionindex = value
                this.case_item.question_options.map(item => delete item.drop_down_options);
                var values = title.split(" ");
                //console.log(values)
                this.$root.title = values;
                const result1 = this.case_item.question_options.find(item => item.title == values[1]);
                const result = this.case_item.question_options.find(item => item.key == result1.key);
                console.log('answer1',result)
                result.serial_no = this.$root.gap_length,
                result.is_correct_answer = true;
                try {
                    const index = this.answer.findIndex(a => a.key === result.key);
                   console.log('index',index)
                if(index < -1) {
                    this.answer.splice(index, 1);
                } else{
                    console.log('this.answer',this.answer)
                }
                } catch(e) {
                    console.log(e);
                }
                this.answer.push(result);
                this.answer = this.answer.filter((v,i,a)=>a.findIndex(t=>(t.serial_no === v.serial_no))===i);
                this.$root.answer_options = this.answer;
                console.log('answer',this.$root.answer_options)
            }  
            event.target.appendChild(document.getElementById(data));
            document.getElementById("demo").innerHTML = "The element was dropped";
        },
        highlightWord(){
             let text = this.case_item.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
             //console.log(text)
             this.highlightWords = text.replace(/<(\/)?i([^>]*)>/g, '<$1span id="fillInGap"$2><$1span id="remove"$2>');
        },
        data(){
            const tx = document.querySelectorAll('#fillInGap')
            for (let i = 0; i < tx.length; i++) {
                tx[i].id = "fill_gap"+`${i+1}`;  
            } 
            const val = document.querySelectorAll('#FillInGap > i')
            for (let j = 0; j < val.length; j++) {
                val[j].id =("fill_gaps"+`${j+1}`); 
                val[j].classList.add('fill_gap')
            } 
            const x = document.querySelectorAll('#fill_in_gap > span')
            for (let k = 0; k < x.length; k++) {
                const node = document.getElementById("fill_gaps"+`${k+1}`)
                document.getElementById("fill_gap"+`${k+1}`).appendChild(node); 
                document.getElementById("remove").remove()
            } 
        },
        
    }
}
</script>

<style scoped>
.answers .target{
    border: 1.5px solid #cfcaca;
    min-width: 110px;
    margin: 0 3px;
    text-align: center;
    min-height: 30px;
}
.fill_Gap_answer .fill_gap .droptarget{
    display: none;
}
#fill_in_gap .fill_gap span{
   display: inline-flex;
   justify-content: center;
   position: relative;
   margin: 3px;
}
#fill_in_gap .fill_gap{
    vertical-align: middle;
}
#remove{
    display: none;
}
.back-light_gray{
   background: #c6d0d9
}
.back-gray-light{
   background: #e6e4e5
}
.target span{
    background: whitesmoke;
    z-index: 1;
}
.hide_class .droptarget span span{
    display: none;
}
.fill_gap p,
.drop_body .disable_p_events,
.disable_pt_events.data{
    pointer-events: none;
}
.drop_body .p_drop{
    background: #c6d0d9;
    border-radius: 4px;
    margin: 5px;
    padding: 5px;
}
</style>