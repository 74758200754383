<template>
    <div>
        <div>
            <div v-if="case_item.title != null">
                <md-content 
                   v-html="case_item.title" 
                   class="md-sl__font_600 md-sl__x1 mb-4 md-content__p1">
                </md-content>
            </div>
            <div v-if="case_item.prompt != null" class="md-question__radio md-sl__font_600 pb-4">
               <md-content 
                    v-html="case_item.prompt" 
                    class="md-sl__font_600 md-sl__x1 mb-4 md-sl__content_color md-content__p1">
                </md-content>
            </div>
        </div>
        <div class="br-4" >
           <div class="md-question__radio md-sl__font_600">
                <TextField 
                   @input="input"
                />
            </div>
        </div>
    </div>
</template>
<script>
import TextField from "./textFieldBox.vue";
export default {
    name: 'Writing',
    components:{
      TextField
    },
    props: {
        case_item: {
          default: () => true
        }
    },
    data() {
      return {
        title: '',
        prompt: '',
        array_length: '',
        search_contents: '',
        general_linguistic: '',
        development_structure_coherence: '',
        match_keyword: [],
        answer_paragraphs: []
      }
    },
    computed: { 

    },
    methods:{
        input(value){
            const enters = value.split(/\r|\r\n|\n/)
            const results = enters.filter(element => {
              return element !== '';
            });
            const paragraphs_array = results.map((item,index) => {
                return {
                    paragraph: item.replace(/\n/g,''),
                    paragraph_no: index+1,
                    sentence_count: item.split(/[.!?]/).length - 1,
                    paragraph_serial:  "MIDDLE"
                }
            }); 
            let {0 : first ,[paragraphs_array.length - 1] : last} = paragraphs_array;
            last.paragraph_serial = 'LAST',first.paragraph_serial = 'FIRST'
            this.search_contents = value ;
            this.$root.answer_paragraphs = paragraphs_array 
            this.$root.answer = value.replace(/\n/g,' ') ;

        },
    },
}
</script>
